import React from 'react';
import { css } from '@emotion/core';
import { H2, P } from '../elements/typography.js';

const Testimonial = ({ quote, source }) => {
  return (
    <div
      css={css`
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        padding: 0px 30px;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;

        @media (min-width: 768px) {
          padding: 30px 15px;
        }
      `}
    >
      <H2
        css={css`
          margin-bottom: 32px;
          @media (min-width: 768px) {
            max-width: 60vw;
          }
        `}
      >
        {quote}
      </H2>
      <P
        css={css`
          text-transform: uppercase;
        `}
      >
        {source}
      </P>
    </div>
  );
};

export default Testimonial;
