import React from 'react';
import Ticker from 'react-ticker';
import { css } from '@emotion/core';

const Marquee = ({ data }) => {
  return (
    <section
      css={css`
        width: 100%;
        margin: 64px 0;

        @media (min-width: 768px) {
          margin: 256px 0;
        }
      `}
    >
      <Ticker height={200} speed={15}>
        {({ index }) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            {data.map(client => {
              return (
                <React.Fragment key={client.id}>
                  <Image url={client.clientLogo.url} />
                </React.Fragment>
              );
            })}
          </div>
        )}
      </Ticker>
    </section>
  );
};

export default Marquee;

const Image = ({ url }) => {
  return (
    <div
      css={css`
        width: 150px;
        height: 200px;
        display: flex;
        align-items: center;
        @media (min-width: 768px) {
          width: 300px;
        }
      `}
    >
      <img src={url} alt="" style={{}} />
    </div>
  );
};
