import React from 'react';
import { css } from '@emotion/core';

export const Grid = ({ children, gap = 64, style }) => {
  const gridStyles = css(css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-bottom: 64px;
    grid-auto-flow: dense;
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 64px;
      margin-bottom: 96px;
    }
    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 256px;
      grid-gap: ${gap}px;
    }
    @media (min-width: 1400px) {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 256px;
      grid-gap: ${gap}px;
    }
  `);

  return (
    <div css={gridStyles} style={style}>
      {children}
    </div>
  );
};

export const Column = ({ children, reverse, style, align, className }) => {
  const columnStyles = css(css`
    grid-column: auto;
    @media (min-width: 768px) {
      grid-column: ${reverse ? 2 : 'inherit'};
      position: relative;
      align-self: ${align};
    }
  `);
  return (
    <div style={style} css={columnStyles} className={className}>
      {children}
    </div>
  );
};
