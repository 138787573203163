import React, { useEffect } from 'react';

import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Image from 'gatsby-image';
import HeroText from '../components/hero-text';
import { setColorMode } from '../utilities/functions';

import ServicesList from '../components/services-list';
import Marquee from '../components/marquee';
import TestimonialSlider from '../components/testimonial-slider';
import Footer from '../components/footer';

const Services = ({ data, location }) => {
  const {
    headlineNode,
    testimonialImage,
    testimonials,
    clientList,
    servicesList,
  } = data.services;
  const { htmlAst } = headlineNode.childMarkdownRemark;

  useEffect(() => {
    setColorMode(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <HeroText headline={htmlAst} centered />
      <ServicesList servicesList={servicesList} />
      <Marquee data={clientList} />
      <div
        css={css`
          max-width: 50vw;
          margin: 0 auto 64px auto;
          @media (min-width: 768px) {
            max-width: 25vw;
            margin: 0 auto 4vw auto;
          }
        `}
      >
        <Image fluid={testimonialImage.fluid} alt={testimonialImage.alt} />
      </div>
      <TestimonialSlider testimonials={testimonials} />
      <Footer pathname={location.pathname} />
    </>
  );
};

export default Services;

export const query = graphql`
  query ServicesQuery {
    services: datoCmsService {
      id
      title
      headlineNode {
        childMarkdownRemark {
          htmlAst
        }
      }
      clientList {
        ... on DatoCmsClient {
          clientName
          id
          caseStudy {
            slug
          }
          clientLogo {
            url
          }
        }
      }
      servicesList {
        id
        itemContentNode {
          childMarkdownRemark {
            htmlAst
          }
        }
        projectLink1 {
          slug
          title
          featuredImagePortrait {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      testimonialImage {
        alt
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }

      testimonials {
        ... on DatoCmsTestimonial {
          quote
          source
          id
        }
      }
    }
  }
`;
