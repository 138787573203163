import React from 'react';
import { css } from '@emotion/core';
import rehypeReact from 'rehype-react';
import { InView } from 'react-intersection-observer';
import Image from 'gatsby-image';
import gsap from 'gsap';

import { P, H2, H4, LINK, LIST, LISTITEM } from '../elements/typography';

import { Container } from '../elements/layouts';
import { Grid, Column } from '../elements/Grid';
import NavLink from './nav-link';

const renderAst = new rehypeReact({
  createElement: React.createElement,
  Fragment: React.Fragment,
  components: { p: P, h2: H2, h3: H4, a: LINK, li: LISTITEM, ul: LIST },
}).Compiler;

const ServicesList = ({ servicesList }) => {
  const contentStyle = css(css`
    h2 {
      margin-bottom: 16px;
      @media (min-width: 768px) {
        margin-bottom: 32px;
      }
    }
    p {
      line-height: 1.5;
      margin-bottom: 16px;
      @media (min-width: 768px) {
        margin-bottom: 0px;
      }
    }
  `);

  const handleReveal = (inView, entry) => {
    if (inView) {
      const tl = new gsap.timeline();
      const el = entry.target.firstElementChild;
      const itemTitle = el.querySelector('.services__title');
      const itemImages = el.querySelector('.services__images');

      tl.from(
        itemTitle,
        {
          duration: 1.6,
          ease: 'power3.out',
          y: 40,
          autoAlpha: 0,
        },
        '0.5',
      ).from(
        itemImages,
        {
          duration: 2,
          ease: 'power3.out',
          y: 40,
          autoAlpha: 0,
        },
        '0.5',
      );
    }
  };

  return (
    <Container px={128}>
      <section>
        {servicesList &&
          servicesList.map((item, index) => {
            const { projectLink1, itemContentNode, id } = item;

            const { htmlAst } = itemContentNode.childMarkdownRemark;

            return (
              <React.Fragment key={id}>
                <InView
                  threshold={0.05}
                  triggerOnce={true}
                  onChange={(inView, entry) => handleReveal(inView, entry)}
                >
                  <Grid gap={128}>
                    <Column
                      reverse={index === 0 || index === 2}
                      style={{
                        paddingTop: '125%',
                        position: 'relative',
                        visibility: 'hidden',
                      }}
                      className="services__images"
                    >
                      {projectLink1.map(project => {
                        return (
                          <div
                            key={project.slug}
                            css={css`
                              position: absolute;
                              top: 0;
                              left: 0;
                              width: 100%;

                              &:nth-of-type(2) {
                                @media (min-width: 768px) {
                                  transform: translate(5%, -5%);
                                }
                              }
                            `}
                          >
                            <NavLink url={`/work/${project.slug}/`}>
                              <Image
                                fluid={project.featuredImagePortrait.fluid}
                                alt={project.featuredImagePortrait.alt}
                              />
                            </NavLink>
                          </div>
                        );
                      })}
                    </Column>
                    <Column
                      align="center"
                      style={{ maxWidth: '600px', visibility: 'hidden' }}
                      className="services__title"
                    >
                      <div css={contentStyle}>{renderAst(htmlAst)}</div>
                    </Column>
                  </Grid>
                </InView>
              </React.Fragment>
            );
          })}
      </section>
    </Container>
  );
};

export default ServicesList;
