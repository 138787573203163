import React from 'react';
import { css } from '@emotion/core';
import Slider from 'react-slick';

import Testimonial from './testimonial';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const defaultSettings = {
  centerMode: true,
  autoplay: true,
  autoplaySpeed: 6000,
  centerPadding: '0px',
  slidesToScroll: 1,
  slidesToShow: 1,
  cssEase: 'cubic-bezier(0.83, 0, 0.17, 1)',
  speed: 1200,
  pauseOnHover: false,
  swipe: false,
};

const TestimonialSlider = ({ testimonials }) => {
  return (
    <div
      css={css`
        margin-bottom: 96px;
      `}
    >
      <Slider {...defaultSettings}>
        {testimonials &&
          testimonials.map(testimonial => (
            <Testimonial key={testimonial.id} {...testimonial} />
          ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
